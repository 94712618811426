import { Controller } from '@hotwired/stimulus';
import { configurations } from '../constants/selectDropDownConfigs';
import $ from 'jquery';

/**
 * Controller to handle the initialization and event binding for sender and recipient dropdowns,
 * and their associated collection origin and destination elements.
 * It listens for changes on the sender and recipient Select2 dropdowns, and dynamically updates
 * the related collection origin and destination fields based on user selection.
 * The idea is that collection origin should be one of the senders locations
 * The collection destination must be one of the recepients  locations
 */
export default class extends Controller {
  connect() {
    // Get elements with specific IDs
    this.senderElement = this.element.querySelector('#id_sender');
    this.recipientElement = this.element.querySelector('#id_recipient');
    this.collectionOriginElement = this.element.querySelector(
      '#id_collection_origin',
    );
    this.collectionDestinationElement = this.element.querySelector(
      '#id_collection_destination',
    );
    // Attach event listeners
    this.attachListeners();
  }

  /* istanbul ignore next */
  attachListeners() {
    const selectInitializer = new SelectInitializer();
    // Listen for changes on the sender and recipient elements
    if (this.senderElement) {
      const collectionOriginElement = this.collectionOriginElement;
      $('#id_sender').on('select2:select', function (e) {
        handleSenderChange(
          e,
          collectionOriginElement,
          configurations,
          setInitialValue,
          selectInitializer,
        );
      });
    }

    if (this.recipientElement) {
      const collectionDestinationElement = this.collectionDestinationElement;
      $('#id_recipient').on('select2:select', function (e) {
        handleRecipientChange(
          e,
          collectionDestinationElement,
          configurations,
          setInitialValue,
          selectInitializer,
        );
      });
    }
  }
}

/**
 * Handles changes to the sender selection and updates the collection origin element.
 * If the collection origin element has 'selected-data' and 'selected-name' attributes,
 * it sets the initial value using those attributes. Otherwise, it initializes the
 * Select2 dropdown for the collection origin element using the provided configuration.
 *
 * @param {Event} event - The change event from the sender select element.
 * @param {HTMLElement} collectionOriginElement - The element to be updated based on the sender change.
 * @param {Object} configurations - Configuration object containing settings for the Select2 elements.
 */

export function handleSenderChange(
  event,
  collectionOriginElement,
  configurations,
  setInitialValue,
  SelectInitializerInstance,
) {
  const senderId = event.target.value;
  const businessPartnersLocationsSearch = {
    ...configurations['businessPartnersLocationsSearch'],
  };

  const initialURL = businessPartnersLocationsSearch.url;
  businessPartnersLocationsSearch.url = `${initialURL}?partner_id=${senderId}`;

  // Update the collection origin element based on sender change
  if (collectionOriginElement) {
    collectionOriginElement.setAttribute(
      'data-identifier',
      'businessPartnersLocationsSearch',
    );

    if (
      collectionOriginElement.hasAttribute('selected-data') &&
      collectionOriginElement.hasAttribute('selected-name')
    ) {
      businessPartnersLocationsSearch.targetSelectElement =
        collectionOriginElement;
      const config = businessPartnersLocationsSearch;
      setInitialValue(collectionOriginElement, config);
      // clear the input so user manually enter the origin location for the newly selected sender
      $(`#${collectionOriginElement.id}`).val(null).trigger('change');
    } else {
      SelectInitializerInstance.initializeSelect({
        ...businessPartnersLocationsSearch,
        targetSelectElement: collectionOriginElement,
      });
    }
  }
}

/**
 * Handles changes to the recipient selection and updates the collection destination element.
 * If the collection destination element has 'selected-data' and 'selected-name' attributes,
 * it sets the initial value using those attributes. Otherwise, it initializes the
 * Select2 dropdown for the collection destination element using the provided configuration.
 *
 * @param {Event} event - The change event from the recipient select element.
 * @param {HTMLElement} collectionDestinationElement - The element to be updated based on the recipient change.
 * @param {Object} configurations - Configuration object containing settings for the Select2 elements.
 */
export function handleRecipientChange(
  event,
  collectionDestinationElement,
  configurations,
  setInitialValue,
  SelectInitializerInstance,
) {
  const recipientId = event.target.value;
  const businessPartnersLocationsSearch = {
    ...configurations['businessPartnersLocationsSearch'],
  };
  const initialURL = businessPartnersLocationsSearch.url;
  businessPartnersLocationsSearch.url = `${initialURL}?partner_id=${recipientId}`;

  // Update the collection destination element based on recipient change
  if (collectionDestinationElement) {
    collectionDestinationElement.setAttribute(
      'data-identifier',
      'businessPartnersLocationsSearch',
    );

    if (
      collectionDestinationElement.hasAttribute('selected-data') &&
      collectionDestinationElement.hasAttribute('selected-name')
    ) {
      businessPartnersLocationsSearch.targetSelectElement =
        collectionDestinationElement;
      const config = businessPartnersLocationsSearch;
      setInitialValue(collectionDestinationElement, config);
      // clear the input so user manually enter the destination location for the newly selected recipient
      $(`#${collectionDestinationElement.id}`).val(null).trigger('change');
    } else {
      SelectInitializerInstance.initializeSelect({
        ...businessPartnersLocationsSearch,
        targetSelectElement: collectionDestinationElement,
      });
    }
  }
}

/**
 * Initializes a Select2 elements with custom configurations.
 *
 * @param {Object} config - Configuration object containing settings for the Select2 element.
 * @param {HTMLElement} config.targetSelectElement - The target select element to apply Select2 to.
 * @param {string} config.url - The URL for the AJAX request to load select options.
 * @param {number} [config.delay=250] - Delay in milliseconds for the AJAX request.
 * @param {Object} [config.data] - Function or object that defines the data sent with the request.
 * @param {Function} [config.processResults] - Function to process the results from the AJAX call.
 * @param {boolean} [config.cache=true] - Whether or not to cache AJAX results.
 * @param {string} [config.theme='bootstrap-5'] - Theme for the Select2 control.
 * @param {boolean} [config.closeOnSelect=false] - Determines if the dropdown should close upon selection.
 * @param {string} [config.placeholder='Searching'] - Placeholder text for the search box.
 * @param {number} [config.minimumInputLength=1] - Minimum input length for triggering the search.
 * @param {Function} [config.templateResult] - Function to render each option in the dropdown.
 * @param {Function} [config.templateSelection] - Function to render the selected option.
 */
export class SelectInitializer {
  initializeSelect(config) {
    // If you need to initialize select elements with additional configurations, you can do it here
    $(config.targetSelectElement).select2({
      theme: config.theme || 'bootstrap-5',
      closeOnSelect: config.closeOnSelect || false,
      ajax: {
        url: config.url,
        delay: config.delay || 250,
        dataType: 'json',
        data: config.data
          ? config.data
          : function (params) {
              return { search: params.term };
            },
        processResults: config.processResults
          ? config.processResults
          : function (data) {
              return { results: data.results };
            },
        cache: typeof config.cache !== 'undefined' ? config.cache : true,
      },
      templateSelection: config.templateSelection,
      templateResult: config.templateResult,
      placeholder: config.placeholder || 'Searching',
      minimumInputLength: config.minimumInputLength || 1,
    });
  }
}

/**
 * Sets the initial value for a Select2 element, either by selecting an existing option
 * or fetching and appending the option from a remote source if it doesn't exist.
 * the context on form invalid from backend renders form fields with 'selected-data' and 'selected-name'  attribute
 *
 * @param {HTMLElement} selectElement - The select element to initialize.
 */
/* istanbul ignore next */
export function setInitialValue(selectElement, config) {
  const $selectElement = $(selectElement); // Convert DOM element to jQuery object
  const selectedOptionValue = $selectElement.attr('selected-data');
  const selectedOptionName = $selectElement.attr('selected-name');
  const identifier = $selectElement.attr('data-identifier');

  if (!identifier || !selectedOptionValue || !selectedOptionName) {
    return; // Exit if any of the required attributes are missing
  }

  const configURL = config.url;

  // If the option exists, select it, otherwise fetch the data
  if ($selectElement.find(`option[value='${selectedOptionValue}']`).length) {
    $selectElement.val(selectedOptionValue).trigger('change');
  } else {
    // Reuse the initializeSelect method by passing the appropriate config
    const selectInitializer = new SelectInitializer();
    selectInitializer.initializeSelect({
      ...config,
      targetSelectElement: selectElement, // Pass the target element
    });

    // Fetch the preselected item and add it to the control
    $.ajax({
      type: 'GET',
      url: configURL,
    })
      .then(function (data) {
        // Create the option and append to Select2
        const option = new Option(
          selectedOptionName,
          selectedOptionValue,
          true,
          true,
        );
        $(option).data('raw', data); // Store the raw data
        $selectElement.append(option).trigger('change');

        // Manually trigger the `select2:select` event
        $selectElement.trigger({
          type: 'select2:select',
          params: {
            data: data,
          },
        });
      })
      .catch(function (error) {
        console.error('Failed to fetch selected option:', error);
      });
  }
}
