import { Controller } from '@hotwired/stimulus';
import { configurations } from '../constants/selectDropDownConfigs';
import {
  handleSenderChange,
  handleRecipientChange,
  SelectInitializer,
  setInitialValue,
} from './createCollectionForm_controller';
import { removeWheelsFromNumberInputs } from '../../src/utils/generalUtils';
import {
  deliveryFormElementIds,
  setupDeliveryFormElementsVariables,
} from '../constants/deliveries';
import $ from 'jquery';
import {
  autofillDimensions,
  validateLength,
  validateWidth,
  validateWeight,
  validateHeight,
  validateQuantity,
  validateClassOfService,
  CollectionLineItemsFormValidator,
} from '../scripts/deliveries/deliveriesUtils';
import {
  validateOrderDate,
  updateTaxAndTotalAmount,
  validateNewPrice,
  calculateTax,
  validateDate,
  displaySummaryDataFromFormData,
  showNewPriceInputHideSubtotalText,
  fetchPartnerPaymentTerm,
  formateDate,
  getCurrentDateTime,
  orderDateIsFilled,
  populatePaymentTerm,
  extractAllSaleOrderLineItemFormData,
  refetchParcelPriceBeforeSubmit,
  displayAlertMessage,
  getSelectedOptionText,
  fetchParcelPrice,
  postParcelItemData,
  isPriceInputHidden,
  shouldSubmit,
  validateSender,
  validateOrigin,
  validateDestination,
  validateRecipient,
} from '../utils/deliveryItemUtils';

/**
 * Controller to handle the initialization and event binding for sender and recipient dropdowns,
 * and their associated collection origin and destination elements.
 * It listens for changes on the sender and recipient Select2 dropdowns, and dynamically updates
 * the related collection origin and destination fields based on user selection.
 * The idea is that collection origin should be one of the senders locations
 * The collection destination must be one of the recepients  locations
 */
export default class createDeliveryForm extends Controller {
  static formElementsObj = {};
  static SKU_PERCENTAGE;
  static VAT_PERCENTAGE;
  static CREATE_COLLECTION_LINE_URL;

  connect() {
    // Get elements with specific IDs
    this.senderElement = this.element.querySelector('#id_sender');
    this.recipientElement = this.element.querySelector('#id_recipient');
    this.collectionOriginElement = this.element.querySelector('#id_origin');
    this.collectionDestinationElement =
      this.element.querySelector('#id_destination');
    // event listeners handlers

    let NewInputListenerHandlers = {
      updateTaxAndTotalAmount,
      validateNewPrice,
      taxHandlersAndArguments: {
        calculateTax,
        arguments: createDeliveryForm.formElementsObj,
      },
    };

    let attachOrderDateListenersHandlers = {
      validateOrderDate,
      validateOrderDateHandlers: {
        validateDate,
      },
    };
    // Attach event listeners
    this.attachListeners();
    removeWheelsFromNumberInputs();
    this.setupControllerVariables();
    this.attachInputValidationHandlers();
    this.attachNewInputListeners(
      createDeliveryForm.formElementsObj,
      NewInputListenerHandlers,
    );
    this.attachOrderDateListeners(
      createDeliveryForm.formElementsObj,
      attachOrderDateListenersHandlers,
    );
  }

  /* istanbul ignore next */
  attachListeners() {
    const selectInitializer = new SelectInitializer();
    // Listen for changes on the sender and recipient elements
    if (this.senderElement) {
      const collectionOriginElement = this.collectionOriginElement;
      $('#id_sender').on('select2:select', function (e) {
        handleSenderChange(
          e,
          collectionOriginElement,
          configurations,
          setInitialValue,
          selectInitializer,
        );
      });
    }

    if (this.recipientElement) {
      const collectionDestinationElement = this.collectionDestinationElement;
      $('#id_recipient').on('select2:select', function (e) {
        handleRecipientChange(
          e,
          collectionDestinationElement,
          configurations,
          setInitialValue,
          selectInitializer,
        );
      });
    }
  }

  attachNewInputListeners(formElementsObj, NewInputListenerHandlers) {
    if (
      formElementsObj.newPriceInput !== null &&
      formElementsObj.newPriceInput !== undefined
    ) {
      let { taxHandlersAndArguments } = NewInputListenerHandlers;
      formElementsObj.newPriceInput.addEventListener('input', function () {
        // update the tax amount and total amount
        NewInputListenerHandlers.updateTaxAndTotalAmount(
          formElementsObj,
          taxHandlersAndArguments,
        );
        NewInputListenerHandlers.validateNewPrice(formElementsObj);
      });
    }
  }

  attachOrderDateListeners(formElementsObj, handlers) {
    let { validateOrderDateHandlers } = handlers;
    formElementsObj.orderDateInput.addEventListener('change', function () {
      handlers.validateOrderDate(formElementsObj, validateOrderDateHandlers);
    });
  }

  validateForm(externalValidators) {
    // register form inputs for validation
    /**
     * Destructure all the form fields from elements that need to be validated
     * Along with the elements that display their errors messages
     * This is made possible using an IIFE
     */
    let formElementsConstantsObj = createDeliveryForm.formElementsObj;

    // validator functions
    let validators = {
      validateLength,
      validateWidth,
      validateWeight,
      validateHeight,
      validateQuantity,
      validateClassOfService,
      validateSender,
      validateOrigin,
      validateDestination,
      validateRecipient,
    };
    // Destructure
    const formElementsObj = (constantsObj => {
      // Extracting only the needed properties and creating a new object
      const {
        lengthInput,
        lengthInputValueErrMsg,
        widthInput,
        widthInputValueErrMsg,
        heightElement,
        heightInputValueErrMsg,
        weightInput,
        weightInputValueErrMsg,
        quantity,
        quantityInputValueErrMsg,
        classOfServiceElement,
        classOfServiceInputValueErrMsg,
        senderElement,
        originElement,
        recipientElement,
        destinationElement,
        senderErrorContainer,
        originErrorContainer,
        destinationErrorElement,
        recipientErrorElement,
      } = constantsObj;

      return {
        lengthInput,
        lengthInputValueErrMsg,
        widthInput,
        widthInputValueErrMsg,
        heightElement,
        heightInputValueErrMsg,
        weightInput,
        weightInputValueErrMsg,
        quantity,
        quantityInputValueErrMsg,
        classOfServiceElement,
        classOfServiceInputValueErrMsg,
        senderElement,
        originElement,
        recipientElement,
        destinationElement,
        senderErrorContainer,
        originErrorContainer,
        destinationErrorElement,
        recipientErrorElement,
      };
    })(formElementsConstantsObj);
    console.log('externalValidators\n', externalValidators);
    const {
      validateOrderDate: validateOrderDateFunc,
      validateOrderDateHandlers,
    } = externalValidators;
    // instantiate the form validator class
    const formValidator = new CollectionLineItemsFormValidator(
      validators,
      formElementsObj,
    );
    const isFormDimensionsValid = formValidator.validateForm();
    const isOrderDateValid = validateOrderDateFunc(
      formElementsConstantsObj,
      validateOrderDateHandlers,
    );
    const isFormValid =
      isFormDimensionsValid && isOrderDateValid ? true : false;
    return isFormValid;
  }

  setupControllerVariables() {
    const formElementConstantsObj = setupDeliveryFormElementsVariables(
      deliveryFormElementIds,
    );
    createDeliveryForm.formElementsObj = formElementConstantsObj;

    const defaultSKUTaxRateValue = parseFloat(
      formElementConstantsObj.defaultSKUTaxRate.innerText.trim(),
    );
    const SKU_PERCENTAGE = parseFloat(
      defaultSKUTaxRateValue / (defaultSKUTaxRateValue + 100),
    );
    const ZERO_RATED_VAT = formElementConstantsObj.ZERO_RATED_VAT;
    createDeliveryForm.formElementsObj.VAT_PERCENTAGE = !isNaN(
      defaultSKUTaxRateValue,
    )
      ? SKU_PERCENTAGE
      : ZERO_RATED_VAT;
    createDeliveryForm.formElementsObj.SKU_PERCENTAGE = SKU_PERCENTAGE;
    createDeliveryForm.formElementsObj.MINIMUM_PRICE = 0;
  }

  attachInputValidationHandlers() {
    let formElementConstantsObj = createDeliveryForm.formElementsObj;
    let {
      parcelSizeInput,
      lengthInput,
      lengthInputValueErrMsg,
      widthInput,
      widthInputValueErrMsg,
      heightElement,
      heightInputValueErrMsg,
      weightInput,
      weightInputValueErrMsg,
      quantity: quantityInput,
      quantityInputValueErrMsg,
      classOfServiceElement,
      classOfServiceInputValueErrMsg,

      senderElement,
      originElement,
      recipientElement,
      destinationElement,
    } = createDeliveryForm.formElementsObj;
    $(senderElement).on('select2:select', function () {
      validateSender(formElementConstantsObj);
    });
    $(originElement).on('select2:select', function () {
      validateOrigin(formElementConstantsObj);
    });
    $(recipientElement).on('select2:select', function () {
      validateRecipient(formElementConstantsObj);
    });
    $(destinationElement).on('select2:select', function () {
      validateDestination(formElementConstantsObj);
    });

    lengthInput.addEventListener('input', () =>
      validateLength({ lengthInput, lengthInputValueErrMsg }),
    );
    widthInput.addEventListener('input', () =>
      validateWidth({ widthInput, widthInputValueErrMsg }),
    );
    weightInput.addEventListener('input', () =>
      validateWeight({ weightInput, weightInputValueErrMsg }),
    );
    heightElement.addEventListener('input', () =>
      validateHeight({ heightElement, heightInputValueErrMsg }),
    );
    quantityInput.addEventListener('input', () =>
      validateQuantity({ quantity: quantityInput, quantityInputValueErrMsg }),
    );
    classOfServiceElement.addEventListener('change', () =>
      validateClassOfService({
        classOfServiceElement,
        classOfServiceInputValueErrMsg,
      }),
    );
    let validators = {
      validateLength,
      validateWidth,
      validateHeight,
    };
    let inputsWithErrorElements = {
      lengthInput,
      lengthInputValueErrMsg,
      widthInput,
      widthInputValueErrMsg,
      heightElement,
      heightInputValueErrMsg,
    };
    parcelSizeInput.addEventListener('change', () => {
      // Get the selected option
      const selectedOption =
        formElementConstantsObj.parcelSizeInput.options[
          parcelSizeInput.selectedIndex
        ];
      // Get the text content of the selected option
      const selectedTextContent = selectedOption.textContent;
      // Extract the text within parentheses
      const dimensionsRegex = /\((.*?)\)/; // Regular expression to extract text within parentheses
      const match = dimensionsRegex.exec(selectedTextContent);
      const selectedText = match ? match[1] : null;
      const constants = { lengthInput, widthInput, heightElement };
      // Call autofillDimensions function to update input values based on selected text
      if (selectedText) {
        autofillDimensions(
          selectedText,
          constants,
          validators,
          inputsWithErrorElements,
        );
      }
    });
  }

  async handleFetchPrice() {
    // let fetchPriceButton = event.target;
    let formElementConstantsObj = createDeliveryForm.formElementsObj;

    let fetchParcelPriceDependancies = {
      displaySummaryDataFromFormData,
      showNewPriceInputHideSubtotalText,
      fetchPartnerPaymentTerm,
      displaySummaryDataFromFormDataHandlers: {
        getSelectedOptionText,
        formateDate,
        getCurrentDateTime,
        orderDateIsFilled,
        getCurrentDateTimeHandlers: {
          formateDate,
        },
      },
      fetchPartnerPaymentTermHandlers: {
        populatePaymentTerm,
      },
    };

    const validateFormExternalValidators = {
      validateOrderDate,
      validateOrderDateHandlers: {
        validateDate,
      },
    };

    // validate the form data
    let isFormValid = this.validateForm(validateFormExternalValidators);
    if (isFormValid) {
      let { salesOrderLineForm, priceSection } = formElementConstantsObj;
      salesOrderLineForm.setAttribute('hidden', 'true');
      priceSection.removeAttribute('hidden');
      fetchParcelPrice(formElementConstantsObj, fetchParcelPriceDependancies);
    }
  }

  async handleSaveAsDraft(event) {
    let saveBtn = event.target;
    let isSaveAsDraft = true;
    let submitUrl;
    const editingDelivery = saveBtn.dataset.editingDelivery;

    if (editingDelivery == 'true' || editingDelivery == true) {
      let parcelID = saveBtn.dataset.parcelId;
      submitUrl = `/sales_orders/edit_parcel_item/${parcelID}/`;
    } else {
      submitUrl = '/deliveries/api/create_delivery_item/';
    }
    await this.handleSave(isSaveAsDraft, submitUrl);
  }

  async handleSaveAndGenerateShipment(event) {
    let saveBtn = event.target;
    let isSaveAsDraft = false;
    const editingDelivery = saveBtn.dataset.editingDelivery;
    let submitUrl;
    if (editingDelivery == 'true' || editingDelivery == true) {
      let parcelID = saveBtn.dataset.parcelId;
      submitUrl = `/sales_orders/edit_parcel_item/${parcelID}/`;
    } else {
      submitUrl = '/deliveries/api/create_delivery_item/';
    }
    await this.handleSave(isSaveAsDraft, submitUrl);
  }

  async handleSave(saveAsDraft, submitUrl) {
    const extractAllSaleOrderLineItemFormDataHandlers = {
      orderDateIsFilled,
      formateDate,
    };
    const refetchParcelPriceBeforeSubmitHandlers = {
      calculateTax,
      postParcelItemData,
      postParcelItemDataHandlers: {
        displayAlertMessage,
      },
    };

    const saveAndEditPrice = false;
    const formElementsObj = createDeliveryForm.formElementsObj;
    formElementsObj.submitUrl = submitUrl;

    const parcelData = extractAllSaleOrderLineItemFormData(
      formElementsObj,
      extractAllSaleOrderLineItemFormDataHandlers,
    );

    const priceInputHidden = isPriceInputHidden(formElementsObj);
    const priceInputHasPrice = priceInputHidden
      ? false
      : validateNewPrice(formElementsObj);

    if (shouldSubmit(priceInputHidden, priceInputHasPrice)) {
      refetchParcelPriceBeforeSubmit(
        parcelData,
        saveAndEditPrice,
        saveAsDraft,
        formElementsObj,
        refetchParcelPriceBeforeSubmitHandlers,
      );
    }
  }

  previousPage() {
    let { salesOrderLineForm, priceSection } =
      createDeliveryForm.formElementsObj;
    salesOrderLineForm.removeAttribute('hidden');
    priceSection.setAttribute('hidden', true);
  }
}
